import _Header from '../_app/cuchillo/layout/Header';
import { Power3 } from "gsap";
import { GetBy, C } from 'assets/scripts/_app/cuchillo/core/Element';
import { Basics } from 'assets/scripts/_app/cuchillo/core/Basics';

export default class Header extends _Header {
  static options = {
    show: {
      duration: .3,
      delay: 0,
      ease: Power3.easeOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static init() {
    super.init();

    /* HOVER */
    this.container.addEventListener(Basics.mouseOver, e => {
      this.container.setAttribute("aria-expanded", "true");
    });
    this.container.addEventListener(Basics.mouseOut, e => {
      this.container.setAttribute("aria-expanded", "false");
    });
  }

  static resize() {
    super.resize();

    const groups = GetBy.class("header__group", this.container);
    let heightTitle = 0
    let heightGroup = 0;

    C.forEach(groups, item => {
      const title = GetBy.class("title", item)[0];
      const ul = GetBy.selector("ul", item)[0];

      heightTitle = Math.max(title.offsetHeight, heightTitle);
      heightGroup = Math.max(ul.offsetHeight, heightGroup);
    });

    this.container.style.setProperty("--height-close", heightTitle + "px");
    this.container.style.setProperty("--height-open", heightGroup + "px");
  }
}


