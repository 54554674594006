export const Colors = {
    "white": {
        hex: 0xFFFFFF,
        css: "#FFFFFF",
        str: "palette-white"
    },
    "black": {
        hex: 0x060804,
        css: "#060804",
        str: "palette-black"
    },
    "primary": {
        hex: 0xdbdbdb,
        css: "#dbdbdb",
        str: "palette-primary"
    },
    "secondary": {
        hex: 0x060804,
        css: "#060804",
        str: "palette-black"
    },
}
