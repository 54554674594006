import { Keyboard } from '../core/Keyboard';
import { C, GetBy } from '../core/Element';

export class Slider_Item {
  item;
  index;
  slider;
  links;

  constructor(__item, __index, __slider) {
    this.item = __item;
    this.index = __index;
    this.slider = __slider;
    this.links = GetBy.tag("a", __item);

    this.afterHide();
  }

  show(__d = 1) {
    if(!this.item.classList.contains("__active")) {
      this.item.classList.add("__active");
    }
    this.item.setAttribute("aria-hidden", "false");
    for(let i=0, j= this.links.length; i<j; i++) {
      this.links[i].removeAttribute("tabindex");
    }
  }
  hide(__d = 1) {
    this.afterHide();
  }

  afterHide() {
    this.item.classList.remove("__active");
    this.item.setAttribute("aria-hidden", "true");

    for(let i=0, j= this.links.length; i<j; i++) {
      this.links[i].setAttribute("tabindex", "-1");
    }
  }
}

export class Slider_Button {
  item;

  constructor(__item, __index, __call) {
    this.item = __item;
    this.index = __index;
    this.item.addEventListener("click", (e) => {  __call(this.index, null, true); });
  }

  show() {
    this.item.setAttribute("aria-current", "true");
  }

  hide() {
    this.item.setAttribute("aria-current", "false");
  }

  dispose() {}
}

export class Slider  {
  static selector = "[data-slider]";

  id;
  _container = null;
  _controls = null;
  _total = 0;
  _actual = null;
  _enabled = false;
  _items = [];
  _btns = [];

  options = {
    selector: "[data-slider]",
    selectorSlides: "[data-slide]",
    selectorControls: "[data-controls]",
    selectorNext: "[data-next]",
    selectorPrev: "[data-prev]",
    selectorBullet: "[data-bullet]",
    classSlide: Slider_Item,
    classButton: Slider_Button,
    isInfinity: true
  }

  get actual() { return this._actual; }
  get total() { return this._total; }

  get enabled() { return this._enabled; }
  set enabled(__bol) {
    if(this._enabled !== __bol) {
      this._enabled = __bol;

      if(this._enabled) {
        Keyboard.add("ArrowLeft", this.id, () => { this.prev(); });
        Keyboard.add("ArrowRight", this.id, () => { this.next(); });
      } else {
        Keyboard.remove("ArrowLeft", this.id);
        Keyboard.remove("ArrowRight", this.id);
      }
    }
  }

  static init() {
    C.forEach(this.selector, (e) => { new Slider(e) })
  }

  constructor(__container, __classSlide = Slider_Item, __classButton = Slider_Button) {
    this.id = String(new Date().getTime());
    this._container = __container;
    this._controls = this._container.querySelector(this.options.selectorControls);

    this.setupSlides();
    this.setupControls();
    this.goto(0,1,false);
  }

  setupSlides() {
    const slides = Array.from(this._container.querySelectorAll(this.options.selectorSlides));
    this._total = slides.length;
    for(let i = 0; i<this._total; i++) {
      const item = new this.options.classSlide(slides[i], i, this);
      this._items.push(item);
    }
  }

  setupControls() {
    if(!this._controls) return;

    if(this._total <= 1) {
      this._controls.classList.add("--disabled");
    }

    this._btnNext = this._container.querySelector(this.options.selectorNext);
    this._btnPrev = this._container.querySelector(this.options.selectorNext);
    if(this._btnNext) this._btnNext.addEventListener('click', () => this.next());
    if(this._btnPrev) this._btnPrev.addEventListener('click', () => this.prev());

    const bullets = this._container.querySelectorAll(this.options.selectorBullet);
    for (let i = 0, j = bullets.length; i < j; i++) {
      this._btns.push(new this.options.classButton(bullets[i], i, this.goto.bind(this)))
    }
  }

  next() {
    const index =  this._actual + 1;

    if(index === this._total) {
      if(this.options.isInfinity) {
        this.goto(0, 1);
      }
    } else {
      this.goto(index, 1);
    }
  }

  prev() {
    const index =  this._actual - 1;

    if(index < 0) {
      if(this.options.isInfinity) {
        this.goto(this._total - 1, 1);
      }
    } else {
      this.goto(index, 1);
    }
  }

  goto(__index, __direction = null, isUserAction = false) {
    if(__index === this._actual) return;

    if(__direction === null) {
      __direction = __index > this._actual ? 1 : -1;
    }

    if(isUserAction) {
      this._container.setAttribute("aria-live", "polite");
    }
    this.beforeGoto(__direction);

    if(this._actual!= null) {
      this._items[this._actual].hide(__direction);
      if(this._btns.length > 0) this._btns[this._actual].hide(__direction);
    }

    this._actual = __index;
    this._items[this._actual].show(__direction);
    if(this._btns.length > 0) this._btns[this._actual].show(__direction);

    this.afterGoto(__direction);
  }

  afterGoto(__direction) {}
  beforeGoto(__direction) {}
  close() {}
  dispose() {
    this.enabled = false;
  }
}
