import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
//import { Videos } from '../_app/cuchillo/components/Videos';
//import { Acordions } from '../_app/cuchillo/components/Acordions';
import Wrap from '../layout/Wrap';
import { isMobile } from '../_app/cuchillo/core/Basics';
import {GetBy,C} from "../_app/cuchillo/core/Element";
import Header from 'assets/scripts/layout/Header';
import { Slider } from 'assets/scripts/_app/cuchillo/components/Slider';
import { ControllerWindow } from 'assets/scripts/_app/cuchillo/windows/ControllerWindow';
import { Maths } from 'assets/scripts/_app/cuchillo/utils/Maths';
//import Forms from '../_app/cuchillo/forms/FormValidator';


export default class Default extends Page {
  id;
  isWrapAutoRemove = true;
  idTimer;

//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();
    this.id = "__" + new Date().getTime();

    Slider.init();

    this.setupBlocks();
  }

//==================================================================================================================
//          PUBLIC
//==================================================================================================================

  setupBlocks() {
    const change = () => {
      C.forEach(".gradient-block", (e)=> {
        const n = Maths.maxminRandom(4, 0);
        const time = Maths.maxminRandom(20, 10)/10;
        const delay = Maths.maxminRandom(20, 0)/10;


        e.style.setProperty('--grad', `${n}`);
        e.style.setProperty('--time', `${time}`);
        e.style.setProperty('--delay', `${delay}`);
      });
    }

    change();
    this.idTimer = setInterval(change, 4000)
  }

  //SHOW
  beforeShow() {
    Scroll.init(Scroll.AXIS_Y, {smooth:false, multiplicator:1});
    Scroll.setScrollbar(new Scrollbar())
    Scroll.addAll();
    Scroll.resize();
    Scroll.resize();
    Scroll.start();

    ControllerWindow.hideAll();
  }

  show__effect(__call) {
    Header.show();
    Wrap.show(()=> { this.afterShow()} );
  }

  afterShow() {
    super.afterShow();
  }

  //HIDE
  beforeHide() {
    //ControllerWindow.hideAll();
  }

  hide__effect() {
    //Sidemenu.hide();
   // Phrases.show(() => {this.afterHide();});
    clearInterval(this.idTimer);
    Wrap.hide(() => {this.afterHide();});
  }

  afterHide() {
    // TweenLite.killTweensOf(this.container);
    Scroll.hide();
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
  }

  //LOOP
  loop() {
    if(this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage("default", Default)
