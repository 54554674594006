import Win from '../_app/cuchillo/windows/Window';
import { gsap, Power3, Power2 } from "gsap";
import { Basics } from '../_app/cuchillo/core/Basics';
import { C } from '../_app/cuchillo/core/Element';

export default class WinImage extends Win {
  _data;
  _img;
  
  constructor(__container) {
    super(__container, "win-image");
    this.directHide();
  }

  setupImage() {
    this._img = document.createElement("img");
    this._img.setAttribute("src", Basics.tempValue);
    this.container.appendChild(this._img);    
  }

  afterHide() {
    if(this._img) C.remove(this._img);
    this._img = null;
    super.afterHide();
  }

  show() {
    this.setupImage();
    super.show();
  }
}

