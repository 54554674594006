import Win from '../_app/cuchillo/windows/Window';
import { gsap, Power3 } from "gsap";
import { GetBy } from 'assets/scripts/_app/cuchillo/core/Element';

export default class Sidemenu extends Win {
    constructor(__container) {
        super(__container, "sidemenu");
        this.directHide();
    }

    show__effect(__d = 0) {
        gsap.set(this.container,{alpha:0});
        gsap.to(this.container,{alpha:1,duration:.2, ease:Power3.easeIn, onComplete:()=>{this.afterShow()}});
    }

    hide__effect(__d = 0) {
        gsap.to(this.container,{alpha:0, duration:.2, ease:Power3.easeOut, onComplete:()=>{this.afterHide();}});
    }

    directHide() {
        gsap.set(this.container,{alpha:0});
        super.directHide();
    }

    resize() {
        super.resize();
    }
}

var sidemenu = new Sidemenu(GetBy.id("Sidemenu"))